import { format as formatter, parse } from "date-fns";

export const asDate = (dateString: string, locale = "en") => {
  return parse(dateString, "yyyy-MM-dd", new Date());
};

export const formatDate = (date: Date | string, format: string, locale = "en") => {
  if (date instanceof Date) {
    return formatter(date, format);
  } else {
    return formatter(asDate(date, locale), format);
  }
};
