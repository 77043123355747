import { PropTypes } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const IconBubbleContainer = styled("div")({});

type Props = {
  color: PropTypes.Color | "error" | "yellow";
  children?: React.ReactNode;
};

export type IconBubbleProps = Props;

const IconBubble: React.FC<Props> = ({ color, children }) => {
  return (
    <IconBubbleContainer
      sx={(theme) => ({
        width: "2rem",
        height: "2rem",
        padding: "0.5rem",
        borderRadius: "50%",

        "& > svg": {
          width: "1rem",
          height: "1rem",
        },

        "&.default": {
          color: theme.palette.primary.main,
        },
        "&.primary": {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.primary.main,
        },
        "&.secondary": {
          color: theme.palette.common.black,
          backgroundColor: theme.palette.secondary.main,
        },
        "&.error": {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.common.white,
        },
        "&.yellow": {
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.common.black,
        },
      })}
      className={color}
    >
      {children}
    </IconBubbleContainer>
  );
};

export default IconBubble;
